<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
    <div id="data-list-list-view" class="data-list-container">
      <h1>{{ $t("evacuationPlans") }}</h1>
      <br>
      <div class="flex flex-wrap items-center justify-between">
      <div class="flex items-center">
<!--       <vs-button  @click="moov()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">Retour</vs-button>
 -->   </div>
      <div class="flex items-center">
        <vs-button v-if="action_button === 1" @click="missionStep()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">{{ $t("retour") }}</vs-button>
        <vs-button v-if="action_button === 2" @click="validationStep()" color="danger" type="filled" class="mb-base mr-3" icon-pack="feather" icon="icon icon-arrow-left-circle">{{ $t("retour") }}</vs-button>
      </div>
      </div>
      <div v-if="activePrompt1" class="clearfix">
      <div class="vx-row">
        <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
        <p>{{ $t("mission") }}<b style="color: #ff6141">*</b></p>
        <vs-input
              disabled
              name="mission_name"
              v-model="mission_name"
              class="w-full"
            />
         </div>  

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/2">
        <p>{{ $t("evacuationType") }}<b style="color: #ff6141">*</b></p>
        <v-select
        v-if="getlang ==='fr'"
        v-validate="'required'"
        data-vv-validate-on="blur"
        name="evacuationType"
        :reduce="rep => rep.key"
        class="w-full" 
        label="name"
        v-model="evacuationType"
        :options="evacuationType_choises">
      </v-select>
      <v-select
         v-if="getlang ==='en'"
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="evacuationType"
          :reduce="rep => rep.key"
          class="w-full" 
          label="name"
          v-model="evacuationType"
          :options="evacuationType_choises_en">
        </v-select>
      <span class="text-danger text-sm" v-show="errors.has('evacuationType')">{{ errors.first("evacuationType") }}</span>
      </div>

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:1/3 xs:w-1/2">
        
            <p>{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
            <vs-select
                collapse-chips 
                :closeOnSelect="false"
                v-model="site"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="site"
                class="w-full"
            >
        <vs-select-item :key="item1" :value="item1.id" :text="item1.name" v-for="item1 in sites" /></vs-select>
        <span class="text-danger text-sm" v-show="errors.has('site')">{{ errors.first("site") }}</span>
      </div>

      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("priority1") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="priority1"
              v-model="priority1"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("nearestAgency1") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="nearestAgency1"
              v-model="nearestAgency1"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("service1") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="service1"
              v-model="service1"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("phoneNumber1") }}<b style="color: #ff6141"></b></p>
        <vue-tel-input
          v-model="phoneNumber1"
          placeholder="Telephone"
          :mode="national"
          :autoFormat="false"
          :enabledCountryCode="true"
          :enabledFlags="true"
          :validCharactersOnly="true"
          data-vv-validate-on="blur"
          v-validate="'min:8|max:8'"
          name="phoneNumbe1"
          class="w-full"
      >
      </vue-tel-input>
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("distanceAndDurationFromThePlaceOfAccommodation1") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="distanceAndDurationFromThePlaceOfAccommodation1"
              v-model="distanceAndDurationFromThePlaceOfAccommodation1"
              class="w-full"
            />
      </div>

      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("priority2") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="priority2"
              v-model="priority2"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("nearestAgency2") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="nearestAgency2"
              v-model="nearestAgency2"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("service2") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="service2"
              v-model="service2"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("phoneNumber2") }}<b style="color: #ff6141"></b></p>
        <vue-tel-input
          v-model="phoneNumber2"
          placeholder="Telephone"
          :mode="national"
          :autoFormat="false"
          :enabledCountryCode="true"
          :enabledFlags="true"
          :validCharactersOnly="true"
          data-vv-validate-on="blur"
          v-validate="'min:8|max:8'"
          name="phoneNumber3"
          class="w-full"
      >
      </vue-tel-input>
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("distanceAndDurationFromThePlaceOfAccommodation2") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="distanceAndDurationFromThePlaceOfAccommodation2"
              v-model="distanceAndDurationFromThePlaceOfAccommodation2"
              class="w-full"
            />
      </div>

      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("priority3") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="priority3"
              v-model="priority3"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("nearestAgency3") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="nearestAgency3"
              v-model="nearestAgency3"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("service3") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="service3"
              v-model="service3"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("phoneNumber3") }}<b style="color: #ff6141"></b></p>
        <vue-tel-input
          v-model="phoneNumber3"
          placeholder="Telephone"
          :mode="national"
          :autoFormat="false"
          :enabledCountryCode="true"
          :enabledFlags="true"
          :validCharactersOnly="true"
          data-vv-validate-on="blur"
          v-validate="'min:8|max:8'"
          name="phoneNumber3"
          class="w-full"
      >
      </vue-tel-input>
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("distanceAndDurationFromThePlaceOfAccommodation3") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="distanceAndDurationFromThePlaceOfAccommodation3"
              v-model="distanceAndDurationFromThePlaceOfAccommodation3"
              class="w-full"
            />
      </div>
      <div class="vx-col p-6 sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
      <span><b style="color:orange">{{ name_file1_3.length }}{{ ' ' }}{{ $t("fichier") }}(s)</b></span>
          <input  name="name_file1_3" type="file"  class="hidden" ref="uploadImgInput1_3" @change="updateCurrImg1_3" accept=".pdf" multiple >
          <span class="text-danger text-sm" v-show="errors.has('name_file1_3')">{{ errors.first("name_file1_3") }}</span>
          <vs-button @click="$refs.uploadImgInput1_3.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">{{ $t("Choisir") }}</vs-button>
      </div>
      </div>
      <vs-button color="success"  class="float-right mt-4" @click="submitData()" >{{ $t("Valider") }}</vs-button>
      </div>

      <div v-if="activePrompt2" class="clearfix">

      <div   class="vx-row">
      
        <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
        <p>{{ $t("mission") }}<b style="color: #ff6141">*</b></p>
        <vs-input
              disabled
              name="mission_name"
              v-model="mission_name"
              class="w-full"
            />
         </div> 

      <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
        <p>{{ $t("evacuationType") }}<b style="color: #ff6141">*</b></p>
        <v-select
        v-if="getlang ==='fr'"
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="evacuationType"
          :reduce="rep => rep.key"
          class="w-full" 
          label="name"
          v-model="evacuationType"
          :options="evacuationType_choises">
        </v-select>
        <v-select
         v-if="getlang ==='en'"
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="evacuationType"
          :reduce="rep => rep.key"
          class="w-full" 
          label="name"
          v-model="evacuationType"
          :options="evacuationType_choises_en">
        </v-select>
      <span class="text-danger text-sm" v-show="errors.has('evacuationType')">{{ errors.first("evacuationType") }}</span>
      </div>
      <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
        
            <p>{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
            <vs-select
                collapse-chips 
                :closeOnSelect="false"
                v-model="site"
                data-vv-validate-on="blur"
                v-validate="'required'"
                autocomplete
                name="site"
                class="w-full"
            >
        <vs-select-item :key="item1" :value="item1.id" :text="item1.name" v-for="item1 in sites" /></vs-select>
        <span class="text-danger text-sm" v-show="errors.has('site')">{{ errors.first("site") }}</span>
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("priority1") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="priority1"
              v-model="priority1"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("nearestAgency1") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="nearestAgency1"
              v-model="nearestAgency1"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("service1") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="service1"
              v-model="service1"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("phoneNumber1") }}<b style="color: #ff6141"></b></p>
        <vue-tel-input
          v-model="phoneNumber1"
          placeholder="Telephone"
          :mode="national"
          :autoFormat="false"
          :enabledCountryCode="true"
          :enabledFlags="true"
          :validCharactersOnly="true"
          data-vv-validate-on="blur"
          v-validate="'min:8|max:8'"
          name="phoneNumbe1"
          class="w-full"
      >
      </vue-tel-input>
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("distanceAndDurationFromThePlaceOfAccommodation1") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="distanceAndDurationFromThePlaceOfAccommodation1"
              v-model="distanceAndDurationFromThePlaceOfAccommodation1"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("priority2") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="priority2"
              v-model="priority2"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("nearestAgency2") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="nearestAgency2"
              v-model="nearestAgency2"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("service2") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="service2"
              v-model="service2"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("phoneNumber2") }}<b style="color: #ff6141"></b></p>
        <vue-tel-input
          v-model="phoneNumber2"
          placeholder="Telephone"
          :mode="national"
          :autoFormat="false"
          :enabledCountryCode="true"
          :enabledFlags="true"
          :validCharactersOnly="true"
          data-vv-validate-on="blur"
          v-validate="'min:8|max:8'"
          name="phoneNumber3"
          class="w-full"
      >
      </vue-tel-input>
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("distanceAndDurationFromThePlaceOfAccommodation2") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="distanceAndDurationFromThePlaceOfAccommodation2"
              v-model="distanceAndDurationFromThePlaceOfAccommodation2"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("priority3") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="priority3"
              v-model="priority3"
              class="w-full"
            />
      </div>

      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("nearestAgency3") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="nearestAgency3"
              v-model="nearestAgency3"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("service3") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="service3"
              v-model="service3"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("phoneNumber3") }}<b style="color: #ff6141"></b></p>
        <vue-tel-input
          v-model="phoneNumber3"
          placeholder="Telephone"
          :mode="national"
          :autoFormat="false"
          :enabledCountryCode="true"
          :enabledFlags="true"
          :validCharactersOnly="true"
          data-vv-validate-on="blur"
          v-validate="'min:8|max:8'"
          name="phoneNumber3"
          class="w-full"
      >
      </vue-tel-input>
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("distanceAndDurationFromThePlaceOfAccommodation3") }}<b style="color: #ff6141"></b></p>
            <vs-input
              name="distanceAndDurationFromThePlaceOfAccommodation3"
              v-model="distanceAndDurationFromThePlaceOfAccommodation3"
              class="w-full"
            />
      </div>
      <div class="vx-col p-6 sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <span><b style="color:orange">{{ name_file1_4.length }}{{ ' ' }}{{ $t("fichier") }}(s)</b></span>
            <input  name="name_file1_4" type="file"  class="hidden" ref="uploadImgInput1_4" @change="updateCurrImg1_4" accept=".pdf" multiple >
            <span class="text-danger text-sm" v-show="errors.has('name_file1_4')">{{ errors.first("name_file1_4") }}</span>
            <vs-button @click="$refs.uploadImgInput1_4.click()" color="primary" type="filled" icon-pack="feather" icon="icon-upload">{{ $t("Ajouter") }}</vs-button>

      </div>
      <vx-card  class="mt-10" no-shadow card-border>
      <div class="vx-row ">
      <div class="vx-col w-full">
        <div class="flex items-end px-3">
          <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
          <span class="font-medium text-lg leading-none">Document(s)</span>
        </div>
        <vs-divider />
      </div>
      </div>
      <div class="vx-row ">
      </div>
      <div class="vx-row ">
      </div>
      <div class="vx-row ">
      <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
      <template slot="header">

      </template>
      <template slot="thead">
      <vs-th hidden  >N°</vs-th>
      <vs-th>{{ $t("name_file") }}</vs-th>
      <vs-th>Actions</vs-th>
      </template>

      <template slot-scope="{data}">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

        <vs-td hidden :data="data[indextr].id">
          {{data[indextr].id}}
        </vs-td>
        <vs-td :data="data[indextr].sites">
          {{data[indextr].url}}
        </vs-td>           
        <vs-td class="whitespace-no-wrap">
          <div class="flex">
            <vs-button @click.stop="openfiles(data[indextr].url_o)" color="primary" size="small" icon-pack="feather" type="filled" icon="icon-eye">{{ $t("Voir") }}</vs-button>    
            <vs-button @click.stop="deleteProduit(data[indextr].id)"  color="danger" size="small" icon-pack="feather" type="filled" class="ml-2" icon="icon-delete">{{ $t("Supprimer") }}</vs-button>

          </div>
        </vs-td>
      </vs-tr>
      </template>
      </vs-table>
      </div>
      </vx-card>
      </div>
      <vs-button color="success"  class="float-right mt-4" @click="submitData()" >{{ $t("Valider") }}</vs-button>

      </div>

      <div v-if="activePrompt3"  class="clearfix">

      <div  class="vx-row">

        <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
        <p>{{ $t("mission") }}<b style="color: #ff6141">*</b></p>
        <vs-input
              disabled
              name="mission_name"
              v-model="mission_name"
              class="w-full"
            />
         </div> 
        
      <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
        <p>{{ $t("evacuationType") }}<b style="color: #ff6141">*</b></p>
        <v-select
         v-if="getlang ==='fr'"
          disabled
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="evacuationType"
          :reduce="rep => rep.key"
          class="w-full" 
          label="name"
          v-model="evacuationType"
          :options="evacuationType_choises">
        </v-select>
        <v-select
         v-if="getlang ==='en'"
          disabled
          v-validate="'required'"
          data-vv-validate-on="blur"
          name="evacuationType"
          :reduce="rep => rep.key"
          class="w-full" 
          label="name"
          v-model="evacuationType"
          :options="evacuationType_choises_en">
        </v-select>
      </div>
      <div class="vx-col sm:w-1/3 md:w-1/3 lg:w-1/3 xs:w-1/3">
        
            <p>{{ $t("site") }}<b style="color: #ff6141" >*</b> </p>
            <vs-select
                disabled
                collapse-chips 
                :closeOnSelect="false"
                v-model="site"
                data-vv-validate-on="blur"
                autocomplete
                name="site"
                class="w-full"
            >
        <vs-select-item :key="item1" :value="item1.id" :text="item1.name" v-for="item1 in sites" /></vs-select>
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("priority1") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="priority1"
              v-model="priority1"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("nearestAgency1") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="nearestAgency1"
              v-model="nearestAgency1"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("service1") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="service1"
              v-model="service1"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("phoneNumber1") }}<b style="color: #ff6141"></b></p>
        <vue-tel-input
          disabled
          v-model="phoneNumber1"
          placeholder="Telephone"
          :mode="national"
          :autoFormat="false"
          :enabledCountryCode="true"
          :enabledFlags="true"
          :validCharactersOnly="true"
          data-vv-validate-on="blur"
          v-validate="'min:8|max:8'"
          name="phoneNumbe1"
          class="w-full"
      >
      </vue-tel-input>
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("distanceAndDurationFromThePlaceOfAccommodation1") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="distanceAndDurationFromThePlaceOfAccommodation1"
              v-model="distanceAndDurationFromThePlaceOfAccommodation1"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("priority2") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="priority2"
              v-model="priority2"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("nearestAgency2") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="nearestAgency2"
              v-model="nearestAgency2"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("service2") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="service2"
              v-model="service2"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("phoneNumber2") }}<b style="color: #ff6141"></b></p>
        <vue-tel-input
          disabled
          v-model="phoneNumber2"
          placeholder="Telephone"
          :mode="national"
          :autoFormat="false"
          :enabledCountryCode="true"
          :enabledFlags="true"
          :validCharactersOnly="true"
          data-vv-validate-on="blur"
          v-validate="'min:8|max:8'"
          name="phoneNumber3"
          class="w-full"
      >
      </vue-tel-input>
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("distanceAndDurationFromThePlaceOfAccommodation2") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="distanceAndDurationFromThePlaceOfAccommodation2"
              v-model="distanceAndDurationFromThePlaceOfAccommodation2"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("priority3") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="priority3"
              v-model="priority3"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("nearestAgency3") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="nearestAgency3"
              v-model="nearestAgency3"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("service3") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="service3"
              v-model="service3"
              class="w-full"
            />
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("phoneNumber3") }}<b style="color: #ff6141"></b></p>
        <vue-tel-input
          disabled
          v-model="phoneNumber3"
          placeholder="Telephone"
          :mode="national"
          :autoFormat="false"
          :enabledCountryCode="true"
          :enabledFlags="true"
          :validCharactersOnly="true"
          data-vv-validate-on="blur"
          v-validate="'min:8|max:8'"
          name="phoneNumber3"
          class="w-full"
      >
      </vue-tel-input>
      </div>
      <div class="vx-col sm:w-1/5 md:w-1/5 lg:1/5 xs:w-1/5">
        <p>{{ $t("distanceAndDurationFromThePlaceOfAccommodation3") }}<b style="color: #ff6141"></b></p>
            <vs-input
              disabled
              name="distanceAndDurationFromThePlaceOfAccommodation3"
              v-model="distanceAndDurationFromThePlaceOfAccommodation3"
              class="w-full"
            />
      </div>
      <vx-card  class="mt-10" no-shadow card-border>
      <div class="vx-row ">
      <div class="vx-col w-full">
        <div class="flex items-end px-3">
          <feather-icon svgClasses="w-6 h-6" icon="ClipboardIcon" class="mr-2" />
          <span class="font-medium text-lg leading-none">Document(s)</span>
        </div>
        <vs-divider />
      </div>
      </div>
      <div class="vx-row ">
      </div>
      <div class="vx-row ">
      </div>
      <div class="vx-row ">
      <vs-table stripe max-items="3" class="w-full  " pagination search :data="produits_computed">
      <template slot="header">

      </template>
      <template slot="thead">
      <vs-th hidden  >N°</vs-th>
      <vs-th>{{ $t("name_file") }}</vs-th>
      <vs-th>Actions</vs-th>
      </template>

      <template slot-scope="{data}">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

        <vs-td hidden :data="data[indextr].id">
          {{data[indextr].id}}
        </vs-td>
        <vs-td :data="data[indextr].sites">
          {{data[indextr].url}}
        </vs-td>           
        <vs-td class="whitespace-no-wrap">
          <div class="flex">
            <vs-button @click.stop="openfiles(data[indextr].url_o)" color="primary" size="small" icon-pack="feather" type="filled" icon="icon-eye">{{ $t("Voir") }}</vs-button>    
          </div>
        </vs-td>
      </vs-tr>
      </template>
      </vs-table>
      </div>
      </vx-card>
      </div>
      <vs-button color="primary"  class="float-right mt-4" @click="quit()" >{{ $t("Quit") }}</vs-button>
      </div>

    </div>
  </template>
<script>

import { Validator } from 'vee-validate'
const link = 'https://dev.jra.mitigarisk.com/digiraDev'

const dict = {
  custom: {
    evacuationType: {
      required: 'Le champ type d\'évacuation est obligatoire'
    },
    site: {
      required: 'Le champ site est obligatoire'
    },
    phoneNumber1: {
      min: 'Au minimum 8 caractères.',
      max: 'Au minimum 8 caractères.'
    },
    phoneNumber2: {
      min: 'Au minimum 8 caractères.',
      max: 'Au minimum 8 caractères.'
    },
    phoneNumber3: {
      min: 'Au minimum 8 caractères.',
      max: 'Au minimum 8 caractères.'
    }
    
  }
}
Validator.localize('fr', dict)
export default {
  data () {
    return {
      activePrompt3:false,
      activePrompt2:false,
      activePrompt1:false,
      Check_update:false,
      action_button:'',
      missionControle_data:'',
      mission_data:{},
      mission_name:'',
      mission_id:'',
      id:'',
      site:'',
      evacuationType:'',
      priority1:'',
      priority2:'',
      priority3:'',
      nearestAgency1:'',
      nearestAgency2:'',
      nearestAgency3:'',
      service1:'',
      service2:'',
      service3:'',
      phoneNumber1:'',
      phoneNumber2:'',
      phoneNumber3:'',
      distanceAndDurationFromThePlaceOfAccommodation1:'',
      distanceAndDurationFromThePlaceOfAccommodation2:'',
      distanceAndDurationFromThePlaceOfAccommodation3:'',
      documents:'',
      produits_computed:[],
      deleted_id:[],
      evacuationPlans: [],
      sites: [],
      selected: [],
     
      evacuationType_choises: [
        {
          key: 'healthemergency',
          name: 'Urgence sanitaire'
        },
        {
          key: 'securityEmergency',
          name: 'Urgence sécuritaire'
        }
      ],
      evacuationType_choises_en: [
        {
          key: 'health emergency',
          name: 'Health emergency'
        },
        {
          key: 'securityEmergency',
          name: 'Security emergency'
        }
      ],
      dataImg1_3:[],
      name_file1_3:[],
      dataImg1_4:[],
      name_file1_4:[],
      isMounted: false,
      itemsPerPage: 20
     
    }
  },
  computed: {
    getlang () {
      return window.getPrendTaCom.$i18n.locale
    },
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.evacuationPlans.length
    }
  },
 
  methods: {
    missionStep () {
      this.$router.push('/Planifications').catch(() => {})
    },
    validationStep () {
      this.$router.push('/Validations').catch(() => {})
    },
    REMOVE_ITEM_PRODUIT (produits, produitId) {
      const ItemIndex = produits.findIndex((p) => p.id === produitId)
      produits.splice(ItemIndex, 1)
    },

    deleteProduit (id) {
      this.REMOVE_ITEM_PRODUIT(this.produits_computed, id)
      this.deleted_id.push(id)
      this.$vs.notify({
        color:'success',
        icon:'delete',
        text: this.$t('delete_file'),
        position:'top-right'
      })
    
    },
    openfiles (file) {
      window.open(`${ link  }${file}`, '_blank')
    },

    updateCurrImg1_3 (input) {
      if (input.target.files && input.target.files.length >0) {
        for (let i = 0; i < input.target.files.length; i++) {
          this.dataImg1_3.push(input.target.files[i])
          this.name_file1_3.push(input.target.files[i].name)
        }
       
        
      }
    },

    updateCurrImg1_4 (input) {
      if (input.target.files && input.target.files.length >0) {
        for (let i = 0; i < input.target.files.length; i++) {
          this.dataImg1_4.push(input.target.files[i])
          this.name_file1_4.push(input.target.files[i].name)
        }
      }
    },
    addNewData () {
      this.$validator.reset()
      this.intialise()
      this.Check_update = false
      this.activePrompt1 = true
    },
    quit () {
      this.activePrompt3 = false
      this.$validator.reset()
      this.intialise()
      if (this.action_button === 1) {
        this.missionStep()
      } else if (this.action_button === 2) {
        this.validationStep()
      }
    },
    ShowData (data) {
      this.intialise()
      this.id = data.id
      this.site = data.site.id
      this.evacuationType = data.evacuationType
      this.priority1 = data.priority1
      this.priority2 = data.priority2
      this.priority3 = data.priority3
      this.nearestAgency1 = data.nearestAgency1
      this.nearestAgency2 = data.nearestAgency2
      this.nearestAgency3 = data.nearestAgency3
      this.service1 = data.service1
      this.service2 = data.service2
      this.service3 = data.service3
      this.phoneNumber1 = data.phoneNumber1
      this.phoneNumber2 = data.phoneNumber2
      this.phoneNumber3 = data.phoneNumber3
      this.distanceAndDurationFromThePlaceOfAccommodation1 = data.distanceAndDurationFromThePlaceOfAccommodation1
      this.distanceAndDurationFromThePlaceOfAccommodation2 = data.distanceAndDurationFromThePlaceOfAccommodation2
      this.distanceAndDurationFromThePlaceOfAccommodation3 = data.distanceAndDurationFromThePlaceOfAccommodation3
      this.documents = ''
      if (data.evacuationPlanDocuments.length) {
        for (let i = 0; i < data.evacuationPlanDocuments.length; i++) {
          this.bene_object = 
            {
              'id': data.evacuationPlanDocuments[i].id,
              'url':(data.evacuationPlanDocuments[i].url).substring(9),
              'url_o':(data.evacuationPlanDocuments[i].url)
            }
          
          this.produits_computed.push(this.bene_object)
        }
      }
      this.activePrompt3 = true
    },
    editData (data) {
      this.intialise()
      this.id = data.id
      this.site = data.site.id
      this.evacuationType = data.evacuationType
      this.priority1 = data.priority1
      this.priority2 = data.priority2
      this.priority3 = data.priority3
      this.nearestAgency1 = data.nearestAgency1
      this.nearestAgency2 = data.nearestAgency2
      this.nearestAgency3 = data.nearestAgency3
      this.service1 = data.service1
      this.service2 = data.service2
      this.service3 = data.service3
      this.phoneNumber1 = data.phoneNumber1
      this.phoneNumber2 = data.phoneNumber2
      this.phoneNumber3 = data.phoneNumber3
      this.distanceAndDurationFromThePlaceOfAccommodation1 = data.distanceAndDurationFromThePlaceOfAccommodation1
      this.distanceAndDurationFromThePlaceOfAccommodation2 = data.distanceAndDurationFromThePlaceOfAccommodation2
      this.distanceAndDurationFromThePlaceOfAccommodation3 = data.distanceAndDurationFromThePlaceOfAccommodation3
      if (data.evacuationPlanDocuments.length) {
        for (let i = 0; i < data.evacuationPlanDocuments.length; i++) {
          this.bene_object = 
            {
              'id': data.evacuationPlanDocuments[i].id,
              'url':(data.evacuationPlanDocuments[i].url).substring(9),
              'url_o':(data.evacuationPlanDocuments[i].url)

            }
          
          this.produits_computed.push(this.bene_object)
        }
      }
      this.documents = ''
      this.Check_update = true
      this.activePrompt2 = true
    },
    intialise () {
      //this.mission_data = {}
      //this.mission_id = ''
      this.id = ''
      this.site = ''
      this.evacuationType = ''
      this.priority1 = ''
      this.priority2 = ''
      this.priority3 = ''
      this.nearestAgency1 = ''
      this.nearestAgency2 = ''
      this.nearestAgency3 = ''
      this.service1 = ''
      this.service2 = ''
      this.service3 = ''
      this.phoneNumber1 = ''
      this.phoneNumber2 = ''
      this.phoneNumber3 = ''
      this.distanceAndDurationFromThePlaceOfAccommodation1 = ''
      this.distanceAndDurationFromThePlaceOfAccommodation2 = ''
      this.distanceAndDurationFromThePlaceOfAccommodation3 = ''
      this.documents = ''
      this.produits_computed = []
      this.deleted_id = []
      this.dataImg1_3 = []
      this.name_file1_3 = []
      this.dataImg1_4 = []
      this.name_file1_4 = []
      this.Check_update = false
      this.activePrompt1 = false
      this.activePrompt2 = false

    },
    deleteData (id) {
      this.$vs.dialog({
        type:'confirm',
        color: 'danger',
        title: 'Confirmation',
        text: this.$t('enteteDelete'),
        acceptText: this.$t('Confirmer'),
        cancelText: this.$t('Annuler'),
        accept: async () => {
          this.$vs.loading()
          this.$http.delete(`evacuationPlans/${id}/`)
            .then(response => {
              this.getAllevacuationPlans()
              window.getPrendTaCom.success(this.$t('Suppressionevacuation'), response)
            })
            .catch(() => {
              window.getPrendTaCom.error({ message: this.$t('Suppression')  })
            })
        }
      })
  
    },
    submitData () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          const formdata = new FormData()
          this.$vs.loading()
          if (this.Check_update === false) {
            
            formdata.append('mission', this.mission_id)
            formdata.append('site', this.site)
            formdata.append('evacuationType', this.evacuationType)
            formdata.append('priority1', this.priority1)
            formdata.append('priority2', this.priority2)
            formdata.append('priority3', this.priority3)
            formdata.append('nearestAgency1', this.nearestAgency1)
            formdata.append('nearestAgency2', this.nearestAgency2)
            formdata.append('nearestAgency3', this.nearestAgency3)
            formdata.append('service1', this.service1)
            formdata.append('service2', this.service2)
            formdata.append('service3', this.service3)
            formdata.append('phoneNumber1', this.phoneNumber1)
            formdata.append('phoneNumber2', this.phoneNumber2)
            formdata.append('phoneNumber3', this.phoneNumber3)
            formdata.append('distanceAndDurationFromThePlaceOfAccommodation1', this.distanceAndDurationFromThePlaceOfAccommodation1)
            formdata.append('distanceAndDurationFromThePlaceOfAccommodation2', this.distanceAndDurationFromThePlaceOfAccommodation2)
            formdata.append('distanceAndDurationFromThePlaceOfAccommodation3', this.distanceAndDurationFromThePlaceOfAccommodation3)
            if (this.dataImg1_3.length) {
              for (let i = 0; i < this.dataImg1_3.length; i++) {
                formdata.append('documents', this.dataImg1_3[i], this.name_file1_3[i])
              }
            }
      
            //documents:this.documents
            
          } else if (this.Check_update === true) {
            formdata.append('mission', this.mission_id)
            formdata.append('site', this.site)
            formdata.append('evacuationType', this.evacuationType)
            formdata.append('priority1', this.priority1)
            formdata.append('priority2', this.priority2)
            formdata.append('priority3', this.priority3)
            formdata.append('nearestAgency1', this.nearestAgency1)
            formdata.append('nearestAgency2', this.nearestAgency2)
            formdata.append('nearestAgency3', this.nearestAgency3)
            formdata.append('service1', this.service1)
            formdata.append('service2', this.service2)
            formdata.append('service3', this.service3)
            formdata.append('phoneNumber1', this.phoneNumber1)
            formdata.append('phoneNumber2', this.phoneNumber2)
            formdata.append('phoneNumber3', this.phoneNumber3)
            formdata.append('distanceAndDurationFromThePlaceOfAccommodation1', this.distanceAndDurationFromThePlaceOfAccommodation1)
            formdata.append('distanceAndDurationFromThePlaceOfAccommodation2', this.distanceAndDurationFromThePlaceOfAccommodation2)
            formdata.append('distanceAndDurationFromThePlaceOfAccommodation3', this.distanceAndDurationFromThePlaceOfAccommodation3)
            if (this.dataImg1_4.length) {
              for (let i = 0; i < this.dataImg1_4.length; i++) {
                formdata.append('documents_add', this.dataImg1_4[i], this.name_file1_4[i])
              }
            }

            if (this.deleted_id.length) {
              for (let i = 0; i < this.deleted_id.length; i++) {
                formdata.append('documents_remove', this.deleted_id[i])
              }
            }
            //documents:this.documents
            
          }
          let url = 'evacuationPlans/'
          let methods = 'post'
          const message = {
            error: this.$t('save_error'),
            success: this.$t('evacuation_save')
          }
          if (this.id) {
            url += `${this.id}/`
            methods = 'put'
            message.success = this.$t('evacuation_update')
  
          }
  
          this.$http[methods](url, formdata)
            .then((response) => {
              //window.evacuationPlans.getAllevacuationPlans()
              window.getPrendTaCom.success(message.success, response)
              this.intialise()
              this.missionStep()

            })
            .catch((error) => {
              const clefs = Object.keys(error.response.data)
              for (let i = 0; i < clefs.length; i++) {
                const item = clefs[i]
                if (item === 'mission') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('mission'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'site') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('site'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'evacuationType') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('evacuationType'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'priority1') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('priority1'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'priority2') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('priority2'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'priority3') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('priority3'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'nearestAgency1') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('nearestAgency1'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'nearestAgency2') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('nearestAgency2'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'nearestAgency3') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('nearestAgency3'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'service1') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('service1'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'service2') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('service2'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'service3') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('service3'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'phoneNumber1') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('phoneNumber1'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'phoneNumber2') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('phoneNumber2'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'phoneNumber3') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('phoneNumber3'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'distanceAndDurationFromThePlaceOfAccommodation1') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('distanceAndDurationFromThePlaceOfAccommodation1'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'distanceAndDurationFromThePlaceOfAccommodation2') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('distanceAndDurationFromThePlaceOfAccommodation2'),
                    color: 'warning',
                    position: 'top-center'
                  })
                } else if (item === 'distanceAndDurationFromThePlaceOfAccommodation3') {
                  this.$vs.notify({
                    time: 4000,
                    title: 'ATTENTION',
                    text: this.$t('distanceAndDurationFromThePlaceOfAccommodation3'),
                    color: 'warning',
                    position: 'top-center'
                  })
                }
              }
              this.$vs.notify({
                time: 8000,
                title: this.$t('save_error_team'),
                text: error.response.data.error,
                color: 'danger',
                position: 'top-right'
              })
              this.$vs.loading.close()
            })
        }
      })
        
    },
    getAllSites () {
      this.$vs.loading()
      const mission = this.mission_id
      this.$http.post('missionSites/', {mission})
        .then((response) => {
          this.sites = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    },
    getAllevacuationPlans () {
      this.$vs.loading()
      this.$http.get('evacuationPlans/')
        .then((response) => {
          this.evacuationPlans = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  },
  created () {
    window.evacuationPlans = this
    //this.getAllevacuationPlans()
  },
  mounted () {
    this.isMounted = true
    this.missionControle_data = window.Planifications.missionControle
    this.mission_data = window.Planifications.activePrompt1_journey
    this.mission_data_up = window.Planifications.planification_mission_data
    this.mission_id = this.mission_data_up.id
    this.mission_name = this.mission_data_up.poNumber
    if (this.mission_data === 1) {
      this.addNewData()
    } 
    if (this.mission_data === 2) {
      this.ShowData(this.mission_data_up.missionEvacuationPlan[0])
    } 
    if (this.mission_data === 3) {
      this.editData(this.mission_data_up.missionEvacuationPlan[0])
    } 
    if (this.missionControle_data === 1) {
      this.action_button = 1
    } else if (this.missionControle_data === 2) {
      this.action_button = 2

    }
    this.getAllSites()
  }
}
</script>
  
  <style lang="scss">
  #data-list-list-view {
    .vs-con-table {
  
      /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;
  
          .vs-table--search-input {
            width: 100%;
          }
        }
      }
  
      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }
  
      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;
  
          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }
  
      .product-name {
        max-width: 23rem;
      }
  
      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }
  
        .vs-table--search{
          padding-top: 0;
  
          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;
  
            &+i {
              left: 1rem;
            }
  
            &:focus+i {
              left: 1rem;
            }
          }
        }
      }
  
      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;
  
        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }
  
      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;
  
          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }
  
      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  </style>
  <style scoped>
  
  </style>
  